// // import { animateWords } from './agency_utils_cls.js';

// Detectar a slug do URL e definir o array words de acordo com a linguagem
function getWordsByLanguage() {
    const url = window.location.href;
    let words;

    if (url.includes('/es/')) {
        words = ["e-commerce", "retail", "servicios"];
    } else if (url.includes('/br/')) {
        words = ["e-commerce", "varejo", "serviços"];
    } else if (url.includes('/pt/')) {
        words = ["e-commerce", "retalho", "serviços"];
    } else {
        words = ["e-commerce", "retail", "services"]; // Inglês por padrão
    }

    return words;
}

const words = getWordsByLanguage();

document.addEventListener("DOMContentLoaded", () => {
    animateWords();
});

//TODO : Remove this function and find way to import from agency_utils_cls.js
function animateWords() {
    if (jQuery(".word-animation").length > 0) {

        // const words = ["e-commerce", "retalho", "serviços"]; // Words to animate
        const container = jQuery(".word-animation");
        let wordIndex = 0;
        let letterIndex = 10;

        function typeText() {

            if (letterIndex < words[wordIndex].length) {
                container[0].innerText += words[wordIndex][letterIndex];
                letterIndex++;
                setTimeout(typeText, 100); // Adjust typing speed here
            } else {
                setTimeout(deleteText, 1000); // Pause before deleting
            }
        }

        function deleteText() {
            if (letterIndex > 0) {
                container[0].innerText = words[wordIndex].slice(0, --letterIndex);
                setTimeout(deleteText, 100); // Adjust deleting speed here

            } else {
                wordIndex = (wordIndex + 1) % words.length; // Move to next word
                setTimeout(typeText, 500); // Pause before typing next word
            }
        }
        setTimeout(typeText, 1000); // Start the animation
    }
}
// export { animateWords };